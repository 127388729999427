body{
    width: 100vh;
    overflow-x: hidden;
}
.coming-soon-container {
    width: 100vw;
    min-height: 100vh;
    background-image: url("../src/assets/img/bg-coming-soon.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 15px;
}

.title-logo {
    font-size: 3rem;
    font-weight: bold;
}

.title {
    font-size: 2rem;
    color: yellow;
}

.text-yellow {
    color: yellow !important;
}

ul {
    margin-left: -15px;
}

ul li {
    margin-top: 10px;
}

.box {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 15px;
}

.box a {
    color: #fff !important;
}

.text-right {
    text-align: right;
}

.glitch {
    color: white;
    font-size: 3rem;
    position: relative;
    margin: 0 auto;
    font-weight: bold;
    text-align: center;
}
  
  @keyframes noise-anim {
    0% {
      clip: rect(52px, 9999px, 2px, 0);
    }
    5% {
      clip: rect(80px, 9999px, 91px, 0);
    }
    10% {
      clip: rect(27px, 9999px, 8px, 0);
    }
    15% {
      clip: rect(87px, 9999px, 95px, 0);
    }
    20% {
      clip: rect(27px, 9999px, 92px, 0);
    }
    25% {
      clip: rect(18px, 9999px, 17px, 0);
    }
    30% {
      clip: rect(20px, 9999px, 59px, 0);
    }
    35% {
      clip: rect(43px, 9999px, 73px, 0);
    }
    40% {
      clip: rect(70px, 9999px, 29px, 0);
    }
    45% {
      clip: rect(61px, 9999px, 69px, 0);
    }
    50% {
      clip: rect(11px, 9999px, 68px, 0);
    }
    55% {
      clip: rect(14px, 9999px, 81px, 0);
    }
    60% {
      clip: rect(59px, 9999px, 23px, 0);
    }
    65% {
      clip: rect(16px, 9999px, 66px, 0);
    }
    70% {
      clip: rect(47px, 9999px, 74px, 0);
    }
    75% {
      clip: rect(50px, 9999px, 75px, 0);
    }
    80% {
      clip: rect(15px, 9999px, 82px, 0);
    }
    85% {
      clip: rect(83px, 9999px, 51px, 0);
    }
    90% {
      clip: rect(56px, 9999px, 43px, 0);
    }
    95% {
      clip: rect(87px, 9999px, 70px, 0);
    }
    100% {
      clip: rect(96px, 9999px, 18px, 0);
    }
  }
  .glitch:after {
    content: attr(data-text);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-shadow: -1px 0 red;
    top: 0;
    color: white;
    background: #293c85;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
  }
  
  @keyframes noise-anim-2 {
    0% {
      clip: rect(31px, 9999px, 13px, 0);
    }
    5% {
      clip: rect(77px, 9999px, 17px, 0);
    }
    10% {
      clip: rect(81px, 9999px, 89px, 0);
    }
    15% {
      clip: rect(17px, 9999px, 1px, 0);
    }
    20% {
      clip: rect(65px, 9999px, 49px, 0);
    }
    25% {
      clip: rect(53px, 9999px, 58px, 0);
    }
    30% {
      clip: rect(24px, 9999px, 59px, 0);
    }
    35% {
      clip: rect(99px, 9999px, 40px, 0);
    }
    40% {
      clip: rect(93px, 9999px, 78px, 0);
    }
    45% {
      clip: rect(40px, 9999px, 89px, 0);
    }
    50% {
      clip: rect(17px, 9999px, 22px, 0);
    }
    55% {
      clip: rect(78px, 9999px, 13px, 0);
    }
    60% {
      clip: rect(72px, 9999px, 100px, 0);
    }
    65% {
      clip: rect(38px, 9999px, 46px, 0);
    }
    70% {
      clip: rect(61px, 9999px, 83px, 0);
    }
    75% {
      clip: rect(77px, 9999px, 44px, 0);
    }
    80% {
      clip: rect(90px, 9999px, 82px, 0);
    }
    85% {
      clip: rect(90px, 9999px, 37px, 0);
    }
    90% {
      clip: rect(4px, 9999px, 83px, 0);
    }
    95% {
      clip: rect(98px, 9999px, 48px, 0);
    }
    100% {
      clip: rect(45px, 9999px, 12px, 0);
    }
  }
  .glitch:before {
    content: attr(data-text);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-shadow: 1px 0 #293c85;
    top: 0;
    color: white;
    background: #293c85;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
  }
  